.gradient {
  background: linear-gradient(90deg, #d53369 0%, #daae51 100%);
}

.text-gradient {
  background: linear-gradient(90deg, #d53369 0%, #daae51 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

html { scroll-behavior: smooth; }